import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

import { AlertModule } from './components/alerts/alert.module';
import { CustomPipesModule } from './pipes/custom-pipes.module';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { LogoModule } from './components/logo/logo.module';
import { PageTitleModule } from './components/page-title/page-title.module';

@NgModule({
  exports: [
    AlertModule,
    CustomPipesModule,
    FooterModule,
    HeaderModule,
    LogoModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    PageTitleModule,
    TranslateModule
  ],
  imports: [
    AlertModule,
    CommonModule,
    CustomPipesModule,
    FooterModule,
    HeaderModule,
    LogoModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    PageTitleModule,
    TranslateModule
  ],
  providers: [
    DatePipe,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000, verticalPosition: 'top' } }
  ]
})
export class EyefinityModule { }
