import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigurationService } from '../services/app-configuration/app-configuration.service';

/**
 * Custom loader for externalizing text, images, links, etc.
 */
export class CustomLoader implements TranslateLoader {
    /**
     * Initializes instance if the CustomLoader class.
     * @param _http Http client.
     * @param _config App configuraion service.
     */
    constructor(
        private _http: HttpClient,
        private _config: AppConfigurationService
    ) { }

    /**
     * Get translations for the specified language.
     * @param langCode 
     * @returns {any}
     */
    getTranslation(langCode: string): Observable<object> {
        return this._http.get(this._config.getAssetPath(`i18n/${langCode}.json`));
    }
}