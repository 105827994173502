<div class="d-flex gap-3" [ngClass]="cssClass">
    <!-- Icon -->
    <div [ngSwitch]="options.type">
        <mat-icon *ngSwitchCase="'Error'" aria-label="Error" class="d-block material-icons-round" fontIcon="error_outline"></mat-icon>
        <mat-icon *ngSwitchCase="'Warning'" aria-label="Warning" class="d-block material-icons-round" fontIcon="warning_amber"></mat-icon>
        <mat-icon *ngSwitchDefault aria-label="Info" class="d-block material-icons-round" fontIcon="info_outline"></mat-icon>
    </div>

    <div class="justify-content-center d-flex flex-column">

        <!-- Title -->
        <div class="fw-bold" *ngIf="title?.length || options.title?.length">
            {{ title?.length ? title : options.title }}
        </div>

        <!-- Message body from options -->
        <div *ngIf="options.message; else content">{{ options.message }}</div>

        <!-- Message body from content -->
        <ng-template #content>
            <div><ng-content></ng-content></div>
        </ng-template>

    </div>
</div>