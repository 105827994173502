/**
 * Environment configuration for staging.
 */
export const environment = {
  development: false,
  links: {
    dailyClosing: 'Common/DailyClosing',
    dailyOpening: 'Common/DailyOpening',
    tillDailyClosing: 'Common/TillDailyClosing',
  },
  production: false,
  staging: true
};
