import { Component, AfterViewInit, NgZone } from '@angular/core';

/** Need to declare window as any so we can run code outside of angular. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

/**
 * Dialog component for patients added from the online scheduler which need to be reviewed.
 */
@Component({
  selector: 'ef-review-patients',
  templateUrl: './review-patients.dialog.html',
  styleUrls: ['./review-patients.dialog.scss']
})
export class ReviewPatientsDialog implements AfterViewInit {

  /**
   * Initializes instance of ReviewPatientsDialog class.
   * @param ngZone Execution context.
   */
  constructor(private _ngZone: NgZone) { }

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit(): void {
    // initial view model (legacy)
    this._ngZone.runOutsideAngular(() => {
      if (window.PatientReviewBannerViewModel) {
        if (!window.patientReviewBannerVm) {
          window.patientReviewBannerVm = new window.PatientReviewBannerViewModel();
          window.ko.applyBindings(window.patientReviewBannerVm, document.getElementById('patientsReviewDialog'));
        }

        // patient review modal (legacy)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const vm: any = window.patientReviewBannerVm;
        vm.showPatientReviewPanel(true);
        vm.buildPatientReviewTable();
        vm.loadPatientReviewTable();
      }
    });
  }
}
