<ng-template #template *ngIf="messageType">
    <!-- Frame data availability message -->
    <ef-alert data-ef="frame-data-availability-alert" [title]="'ALERTS.FRAME_DATA_AVAILABILITY.TITLE'|translate"
        [options]="alert" [ngSwitch]="res[0]" *ngIf="messageType==='Standard'&&res[0]!=='EX_ERR'">

        <div *ngSwitchCase="'TA'">
            <span>{{ 'ALERTS.FRAME_DATA_AVAILABILITY.DAY_ALERT' | translate: {days: res[1]} }}</span>
            <span>Customers are required to have a Frames Data IDA subscription. Click here for a
                <a href="#" (click)="gotoOffice($event)">New subscription</a> or
                <a href="#" (click)="gotoOffice($event)">Verify an account</a></span>
        </div>

        <div *ngSwitchCase="'A_EI'">
            <span>{{ 'ALERTS.FRAME_DATA_AVAILABILITY.ACCOUNT_EXPIRE_SOON' | translate }}</span>
            <span>Thank you for using Frames Data! Your account will expire in <b>{{ res[1] }}
                    days</b>. Please
                <a href="#" (click)="gotoOffice($event)">Renew Now</a> or call 1-800-739-7555 to speak to Frames
                Data
                customer service. For multi-location licenses please call 1-800-821-6069 ext. 4. Thank you!</span>
        </div>

        <div *ngSwitchCase="'EX_NA'">
            <span>{{ 'ALERTS.FRAME_DATA_AVAILABILITY.SIGN_UP' | translate }}</span>
            <span>YOUR FRAMES DATA IDA SUBSCRIPTION IS INVALID.
                <a href="#" (click)="gotoOffice($event)">Sign up for new subscription</a> or
                <a href="#" (click)="gotoOffice($event)">Verify an account</a></span>
        </div>

        <div *ngSwitchCase="'EX_A'">
            <span>{{ 'ALERTS.FRAME_DATA_AVAILABILITY.ACCOUNT_EXPIRED' | translate }}</span>
            <span>I'm sorry, this Frames Data account has expired. Please <a href="#" (click)="gotoOffice($event)">Renew
                    Now</a> or call 1-800-739-7555 to speak to Frames Data customer service. For multi-location
                licenses please call 1-800-821-6069 ext. 4. Thank you!</span>
        </div>

    </ef-alert>

    <!-- Frame data availability message (jobson) -->
    <ef-alert data-ef="frame-data-availability-alert-jobson" [title]="'ALERTS.FRAME_DATA_AVAILABILITY.TITLE'|translate"
        [options]="alert" *ngIf="messageType==='Jobson'">
        <div [ngSwitch]="res[0]">

            <span *ngSwitchCase="'TA'"
                [innerHTML]="'ALERTS.FRAME_DATA_AVAILABILITY.SUBSCRIPTION_REQUIRED' | translate : {days:res[1]}"></span>
            <span *ngSwitchCase="'A_EI'">{{ 'ALERTS.FRAME_DATA_AVAILABILITY.ACCOUNT_EXPIRE_SOON' | translate }}</span>
            <span *ngSwitchCase="'EX_NA'">{{ 'ALERTS.FRAME_DATA_AVAILABILITY.SIGN_UP' | translate }}</span>
            <span *ngSwitchCase="'EX_A'">{{ 'ALERTS.FRAME_DATA_AVAILABILITY.ACCOUNT_EXPIRED' | translate }}</span>
            <span *ngSwitchCase="'EX_ERR'">{{ 'ALERTS.FRAME_DATA_AVAILABILITY.ERROR' | translate }}</span>

            <span>{{ messageJobson }}</span>
        </div>
    </ef-alert>
</ng-template>