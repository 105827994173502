import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AppConfigurationService, IAdminOfficeContext } from '../../../common';

/**
 * Service for working with company data.
 */
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  /** Admin office context. */
  private _adminOfficeContext: BehaviorSubject<IAdminOfficeContext>;
  /** API controller. */
  private _controller = 'CompanyInformation';
  /** Admin office context key used for session storage. */
  private _adminOfficeContextKey = 'adminOfficeContext';

  /**
   * Initializes instance of the CompanyService class.
   * - Set default value for admin office context.
   * @param _config Application configuration service
   * @param _http Http client
   */
  constructor(
    private _config: AppConfigurationService,
    private _http: HttpClient
  ) {
    const officeExternalId = this._config.officeExternalId;
    this._adminOfficeContext = new BehaviorSubject<IAdminOfficeContext>({
      companyId: this._config.companyExternalId,
      officeNumber: officeExternalId || '',
      offices: officeExternalId ? [officeExternalId] : []
    });
  }

  /**
   * Load admin office context from API.
   */
  async loadAdminOfficeContext(): Promise<void> {
    return new Promise<void>(resolve => {
      const url = `${this._controller}/GetOffices?companyId=${encodeURIComponent(this._config.companyExternalId)}`;
      this._http.get<string[]>(url).subscribe({
        next: offices => {
          const adminOfficeContext = this._adminOfficeContext.getValue();
          adminOfficeContext.offices = offices;
          sessionStorage.setItem(this._adminOfficeContextKey, JSON.stringify(adminOfficeContext));
          resolve();
        }
      });
    });
  }
}
