import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { FulfillmentService } from 'src/app/common/services/fulfillment/fulfillment.service';
import { IAlert } from 'src/app/common/interfaces/alert';
import { AppConfigurationService } from 'src/app/common/services/app-configuration/app-configuration.service';

/**
 * Component for frame board management alert message.
 */
@Component({
  selector: 'ef-frame-board-management-alert',
  templateUrl: './frame-board-management-alert.component.html',
  styleUrls: ['./frame-board-management-alert.component.scss']
})
export class FrameBoardManagementAlertComponent implements OnInit {
  /** Failed frame order count. */
  public failedOrderCount = 0;

  /** Alert options with default values. */
  private _options: IAlert = { type: 'Error' };

  /** Alert options. */
  @Input() set options(value: IAlert) {
    Object.assign(this._options, value);
  }
  get options(): IAlert {
    return this._options;
  }

  /** Template used to render content outside of selector. */
  @ViewChild('template') template!: TemplateRef<never>;

  /**
   * Initializes instance of FrameBoardManagementAlertComponent class.
   * @param _config Application configuration sertvice
   * @param _changeDetectorRef Change detector
   * @param _retail Retail service
   */
  constructor(
    private _config: AppConfigurationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fulfillment: FulfillmentService
  ) { }

  /**
   * OnInit lifecycle hook.
   * - get failed frame order count.
   */
  ngOnInit(): void {
    this._fulfillment.getFailedFrameOrders().then(failedFrameOrders => {
      this.failedOrderCount = failedFrameOrders.TotalAvailable;
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    });
  }

  /**
   * Navigate to review failed orders.
   * @param event DOM event
   */
  review(event: Event): void {
    event.preventDefault();
    window.location.href = this._config.getLinkPath('FrameBoardManagement/ReviewFailedOrders');
  }
}
