import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewFailedOrdersComponent } from './review-failed-orders.component';
import { ReviewFailedOrdersRoutingModule } from './review-failed-orders-routing.module';
import { EyefinityModule } from 'src/app/common';


@NgModule({
  declarations: [
    ReviewFailedOrdersComponent
  ],
  imports: [
    CommonModule,
    EyefinityModule,
    ReviewFailedOrdersRoutingModule
  ]
})
export class ReviewFailedOrdersModule { }
