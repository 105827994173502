import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { AlertComponent } from './alert/alert.component';
import { StoreOperationsAlertComponent } from './store-operations-alert/store-operations-alert.component';
import { FrameDataAvailabilityAlertComponent } from './frame-data-availability-alert/frame-data-availability-alert.component';
import { FrameBoardManagementAlertComponent } from './frame-board-management-alert/frame-board-management-alert.component';
import { ReviewPatientsAlertComponent } from './review-patients-alert/review-patients-alert.component';
import { ReviewPatientsModule } from '../../dialogs/review-patients/review-patients.module';
import { SystemFailureAlertComponent } from './system-failure-alert/system-failure-alert.component';

@NgModule({
  declarations: [
    AlertComponent,
    StoreOperationsAlertComponent,
    FrameBoardManagementAlertComponent,
    FrameDataAvailabilityAlertComponent,
    ReviewPatientsAlertComponent,
    SystemFailureAlertComponent
  ],
  exports: [
    AlertComponent,
    StoreOperationsAlertComponent,
    FrameBoardManagementAlertComponent,
    FrameDataAvailabilityAlertComponent,
    ReviewPatientsAlertComponent,
    SystemFailureAlertComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ReviewPatientsModule,
    TranslateModule
  ]
})
export class AlertModule { }