import { Component, Input } from '@angular/core';

import { IAlert } from 'src/app/common/interfaces/alert';

/**
 * Component for alert messages.
 */
@Component({
  selector: 'ef-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  /** CSS class for style. */
  public cssClass = 'alert-basic';

  /** Alert options with default values. */
  private _options: IAlert = {
    isGrouped: false,
    style: 'Basic',
    type: 'Info'
  };

  /** Alert options. */
  @Input() set options(value: IAlert) {
    Object.assign(this._options, value);
    switch (this._options.style) {
      case 'Clickable':
        this.cssClass = 'ef-alert-clickable'
        break;
      default:
        this.cssClass = 'ef-alert-basic'
        break;
    }
    this.cssClass += ` ef-alert-${this.options.type?.toLowerCase() || 'info'}`
    if(this.options.isGrouped) this.cssClass += ' ef-alert-grouped';
  }
  get options(): IAlert {
    return this._options;
  }

  /** Alert title. */
  @Input() title?: string;
}