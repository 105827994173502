<div class="align-items-center d-flex justify-content-center pb-2 pt-2">

    <!-- training mode -->
    <div id="accountSwitch" class="d-none d-sm-block fw-bold pe-3 ps-4 training-switch w-25">
        <ng-template [ngIf]="isTrainingEnabled" [ngIfElse]="live">
            <a href="#" (click)="toggleTraining($event)">
                {{'TRAINING.TRAINING_TEXT'|translate}}<span class="d-none d-lg-inline-block">:
                    {{'TRAINING.LIVE_SWITCH'|translate}}</span></a>
        </ng-template>
        <ng-template #live>{{'TRAINING.LIVE_TEXT'|translate}}</ng-template>
    </div>

    <!-- copyright -->
    <div id="copyright" class="flex-fill text-center"
        [innerHTML]="'FOOTER.COPYRIGHT'|translate:{year}"></div> 

    <!-- app version -->
    <div id="version" class="d-none d-sm-block pe-4 ps-3 text-end w-25">
        {{'FOOTER.VERSION'|translate}}: {{version}}</div>

</div>