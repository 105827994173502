import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

/**
 * Application feature service.
 */
@Injectable({
  providedIn: 'root'
})
export class AppFeatureService {
  /** Is analytics and insights enabled?  */
  readonly isAnalyticsAndInsightsEnabled = localStorage.getItem('IsAnalyticsAndInsightsEnabled') === 'true';
  /** Is button click required for patient search? */
  readonly isBtnClickRequiredForPatientSearch = localStorage.getItem('IsBtnClickRequiredForPatientSearch') === 'true';
  /** Is CHW enabled? */
  readonly isChwEnabled = localStorage.getItem('IsChwEnabled') === 'true';
  /** Is ECR om prem? */
  readonly isEcrOnPrem = localStorage.getItem('IsEcrOnPrem') === 'true';
  /** Is hide billing info in front office enabled? */
  readonly isHideBillingInfoInFrontOfficeReportingEnabled = localStorage.getItem('IsHideBillingInfoInFrontOfficeReportingEnabled') === 'true';
  /** Is payment authorization API enabled? */
  readonly isPaymentAuthApi = window.localStorage.getItem('IsPaymentAuthApi') === 'true';
  /** Is schedule appointment for the office enabled? */
  readonly isScheduleApptforotherOfficesEnabled = localStorage.getItem('IsScheduleApptforotherOfficesEnabled') === 'true';
  /** Is till enabled? */
  readonly isTillEnabled = localStorage.getItem('IsTillEnabled') === 'true';
  /** Is VSP Premier dashboard enabled? */
  readonly isVSPPremierDashboardEnabled = localStorage.getItem('IsVSPPremierDashboardEnabled') === 'true';

  /** Is Review Patients Created Online Enabled? */
  public isReviewPatientsCreatedOnlineEnabled = new BehaviorSubject<boolean>(false);

  /**
   * Initializes instance of the AppFeatureService class.
   * @param _http Http client.
   */
  constructor(private _http: HttpClient) {
    this.isReviewPatientsCreatedOnlineEnabled = new BehaviorSubject<boolean>(localStorage.getItem('reviewPatientsCreatedOnlineFeature')?.toLowerCase() === 'true')
  }

  /**
   * Get isReviewPatientsCreatedOnlineEnabled feature value. (TODO - should be able to remove this with better implementation of features.)
   */
  loadIsReviewPatientsCreatedOnlineEnabled(): void {
    const url = 'Patient/GetReviewNeededPatientConfig';
    this._http.get<IReviewNeededPatientConfig>(url).subscribe({
      next: config => this.isReviewPatientsCreatedOnlineEnabled.next(config.reviewPatientsCreatedOnlineFeature),
      error: () => this.isReviewPatientsCreatedOnlineEnabled.next(false)
    });
  }
}

/**
 * Interface for review needed patient config.
 */
interface IReviewNeededPatientConfig {
  /** Patient count. */
  patientCount: number;
  /** Review patients created online feature flag. */
  reviewPatientsCreatedOnlineFeature: boolean;
}