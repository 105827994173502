import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

import { IAppConfig } from './interfaces/app-config';
import { IWalkMePlayerAPI } from './interfaces/walk-me-player-api';
import { IAppWindow } from './interfaces/app-window';

/** Extened window DOM document. */
declare const window: IAppWindow;

/** WalkMe player API. */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const WalkMePlayerAPI: IWalkMePlayerAPI;

/**
 * Application configuration service.  Use this service instead of "window.config".
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {
  /** Anti-forgery token. */
  readonly antiForgeryToken = window.efAppInfo.antiForgeryToken;
  /** Application version. */
  readonly appVersion = window.efAppInfo.appVersion;
  /** Base path. */
  readonly clientBasePath = window.efAppInfo.basePath;
  /** Is routing available? */
  readonly isRoutingAvailable: boolean = document.querySelector('ef-root') !== null;
  /** User daily roles. */
  readonly dailyRoles = window.efAppInfo.dailyRoles;  // TODO: Move to user roles service?
  /** Url for support knowledge base. */
  readonly supportKnowledgeBaseUrl = window.efAppInfo.supportKnowledgeBaseUrl;

  /** Base path. */
  private _basePath;
  /** Application configuration from "window.config". */
  private _config: IAppConfig;
  /** Distribution path. */
  private _distPath;

  /**
   * Initializes instance of the AppConfigurationService class.
   * @param _sanitizer DOM sanitizer service.
   */
  constructor(
    private _http: HttpClient,
    private _sanitizer: DomSanitizer
  ) {
    this._config = window?.config;
    this._basePath = this._config?.baseUrl;
    this._distPath = this.clientBasePath;
  }

  /** Base URL. */
  get baseUrl(): string {
    return this._config.baseUrl || '/';
  }

  /** Company ID. */
  get companyExternalId(): string {
    return this._config.companyId || '';
  }

  /** Frame data availability. */
  get frameDataAvailability(): string | undefined {
    return this._config.frameDataAvailability;
  }

  /** Is EHR enabled? */
  get isEhrEnabled(): boolean {
    return this._config.isEhrEnabled?.toLowerCase() === 'true';
  }

  /** Is premier partner? */
  get isPremier(): boolean {
    return this._config.isPremier === '1';
  }

  /** Is training mode enabled? */
  get isTrainingEnabled(): boolean {
    const isTrainingEnabled = this._config.trainingMode?.toLowerCase() || '';
    return isTrainingEnabled !== 'false' && isTrainingEnabled != '';
  }

  /** Is go live pending? */
  get isTrainingPendingLive(): boolean {
    const isTrainingPendingLive = this._config.trainingPendingLive?.toLowerCase() || '';
    return isTrainingPendingLive !== 'false' && isTrainingPendingLive != '';
  }

  /** Offic ID. */
  get officeInternalId(): number {
    const officeInternalId = Number(this._config.officeId || '0');
    return isNaN(officeInternalId) ? 0 : officeInternalId;
  }

  /** Office number. */
  get officeExternalId(): string | undefined {
    return this._config.officeNumber;
  }

  /** Training company id. */
  get trainingCompanyId(): string {
    return this._config.trainingCompanyId || '';
  }

  /** Timeout (minutes). */
  get timeout(): number {
    const timeout = Number(this._config.timeOut || '122');
    return (isNaN(timeout) || timeout <= 0) ? 122 : timeout;
  }

  /** User ID. */
  get userId(): number {
    const userId = Number(this._config.userId || '0');
    return isNaN(userId) ? 0 : userId;
  }

  /** Username. */
  get username(): string | undefined {
    return this._config.userName;
  }

  /**
   * Get full path of an asset.
   * @param relativePath
   * @returns {string}
   */
  getAssetPath(relativePath: string): string {
    return this._sanitizer.sanitize(SecurityContext.URL, `${this._distPath}assets/${relativePath}`) || '/';
  }

  /**
   * Get full path of a link.
   * @param relativePath
   * @returns {string}
   */
  getLinkPath(relativePath: string): string {
    return this._sanitizer.sanitize(SecurityContext.URL, `${this._basePath}${relativePath}`) || '/';
  }

  /**
   * Toggle training mode.
   */
  toggleTraining(): void {
    this._http.post('Login/SwitchAccounts', this.isTrainingEnabled).subscribe(() => location.reload());
  }

  /**
   * Walk Me Through launcher.
   */
  walkMeThroughLauncher(): void {
    WalkMePlayerAPI.toggleMenu();
  }
}
