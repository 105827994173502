<div class="d-flex flex-wrap gap-3" role="toolbar">

    <!-- apps downdown menu (xs, sm, md) -->
    <div class="d-block d-lg-none" role="toolbar">
        <button mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuApps" aria-haspopup="true"
            aria-expanded="false">
            <mat-icon aria-hidden="true" fontIcon="apps"></mat-icon>
            <span class="d-none d-sm-inline-block">{{'NAVIGATION.APPS'|translate}}</span>
        </button>
        <mat-menu #menuApps="matMenu">
            <ng-template matMenuContent>
                <a id="btn_menuEhrLaunch" mat-menu-item href="#" (click)="launchEhr($event)"
                    *ngIf="isEhrEnabled">{{'NAVIGATION.EHR'|translate}}</a>
                <a id="btn_menuDailyOpening" mat-menu-item [href]="'Common/DailyOpening'|link"
                    *ngIf="isTillEnabled && hasDayOpenRole">{{'NAVIGATION.DAILY_OPENING'|translate}}</a>
                <a id="btn_menuDailyClosing" mat-menu-item
                    [href]="isTillEnabled?'Common/TillDailyClosing':'Common/DailyClosing'|link"
                    *ngIf="hasDayCloseRole">{{'NAVIGATION.DAILY_CLOSING'|translate}}</a>
                <a id="btn_menuChangePaymentType" mat-menu-item [href]="'Management/ChangePayments'|link"
                    *ngIf="hasPaymentTypesRole">{{'NAVIGATION.CHANGE_PAYMENT'|translate}}</a>
                <a id="btn_menuCreditCardProcessingPOS" mat-menu-item [href]="'Common/CreditCardProcessingPOS'|link"
                    *ngIf="isPaymentAuthApi">{{'NAVIGATION.CREDIT_CARD_PROCESSING'|translate}}</a>
                <a id="btn_menuHelp" mat-menu-item href="#" data-url="Content/Help/FrontOff/Eyefinity_PM_FO_Help.htm"
                    (click)="help($event)">{{'NAVIGATION.HELP'|translate}}</a>
            </ng-template>
        </mat-menu>
    </div>

    <!-- ehr (lg, xl) -->
    <button id="btn_ehrLaunch" mat-button class="align-items-center d-none d-lg-flex"
        (click)="launchEhr($event)" *ngIf="isEhrEnabled">
        <mat-icon aria-hidden="true" fontIcon="cloud"></mat-icon>
        <span>{{'NAVIGATION.EHR'|translate}}</span>
    </button>

    <!-- store operations (lg, xl) -->
    <ng-template [ngIf]="numberOfDailyRoles > 1" [ngIfElse]="singleRole">
        <div class="d-none d-lg-block" role="toolbar">
            <button id="btn_dailyClosing" mat-button class="dropdown-toggle"
                [ngClass]="{'btn-first':!isEhrEnabled}" [matMenuTriggerFor]="menuStoreOperations" aria-haspopup="true"
                aria-expanded="false">
                <mat-icon aria-hidden="true" fontIcon="calendar_month"></mat-icon>
                <span id="dailyClosingMenuTitle">{{'NAVIGATION.STORE_OPERATIONS'|translate}}</span>
            </button>
            <mat-menu #menuStoreOperations="matMenu">
                <ng-template matMenuContent>
                    <a mat-menu-item [href]="'Common/DailyOpening'|link"
                        *ngIf="isTillEnabled && hasDayOpenRole">{{'NAVIGATION.DAILY_OPENING'|translate}}</a>
                    <a mat-menu-item [href]="isTillEnabled?'Common/TillDailyClosing':'Common/DailyClosing'|link"
                        *ngIf="hasDayCloseRole">{{'NAVIGATION.DAILY_CLOSING'|translate}}</a>
                    <a mat-menu-item [href]="'Management/ChangePayments'|link" *ngIf="hasPaymentTypesRole">
                        {{'NAVIGATION.CHANGE_PAYMENT'|translate}}</a>
                    <a id="btn_menuCreditCardProcessingPOS" mat-menu-item [href]="'Common/CreditCardProcessingPOS'|link"
                        *ngIf="isPaymentAuthApi">{{'NAVIGATION.CREDIT_CARD_PROCESSING'|translate}}</a>
                </ng-template>
            </mat-menu>
        </div>
    </ng-template>
    <ng-template #singleRole>
        <a id="btn_dailyOpeningAlone" class="btn btn-light" [href]="'Common/DailyOpening'|link"
            *ngIf="isTillEnabled && hasDayOpenRole">
            <i class="icon-dailyclose"></i><span class=" ms-2">{{'NAVIGATION.DAILY_OPENING'|translate}}</span></a>
        <a id="btn_dailyClosingAlone" class="btn btn-light"
            [href]="isTillEnabled?'Common/TillDailyClosing':'Common/DailyClosing'|link" *ngIf="hasDayCloseRole">
            <i class="icon-dailyclose"></i><span class=" ms-2">{{'NAVIGATION.DAILY_CLOSING'|translate}}</span></a>
        <a id="btn_changePaymentType" class="btn btn-light" [href]="'Management/ChangePayments'|link"
            *ngIf="hasPaymentTypesRole">
            <i class="icon-fees"></i><span class=" ms-2">{{'NAVIGATION.CHANGE_PAYMENT'|translate}}</span></a>
        <a id="btn_menuCreditCardProcessingPOS" class="btn btn-light" [href]="'Common/CreditCardProcessingPOS'|link"
            *ngIf="isPaymentAuthApi">
            <i class="icon-fees"></i><span class=" ms-2">{{'NAVIGATION.CREDIT_CARD_PROCESSING'|translate}}</span></a>
    </ng-template>

    <!-- help -->
    <div role="toolbar">
        <button mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuHelp" aria-haspopup="true"
            aria-expanded="false">
            <mat-icon aria-hidden="true" fontIcon="help"></mat-icon>
            <span class="d-none d-sm-inline-block">{{'NAVIGATION.HELP'|translate}}</span>
        </button>
        <mat-menu #menuHelp="matMenu">
            <ng-template matMenuContent>
                <a mat-menu-item href="#" data-url="Content/Help/FrontOff/Eyefinity_PM_FO_Help.htm"
                    (click)="help($event)">{{'NAVIGATION.HELP_TOPICS'|translate}}</a>
                <a id="walkMeThroughLauncher" mat-menu-item href="#" (click)="walkMeThroughLauncher($event)">
                    {{'NAVIGATION.HELP_HOW_TO'|translate}}</a>
                <a id="supportKnowledgeBaseLink" mat-menu-item [href]="supportKnowledgeBaseUrl" target="_blank">
                    {{'NAVIGATION.HELP_KNOWLEDGE_BASE'|translate}}</a>
            </ng-template>
        </mat-menu>
        <ul class="dropdown-menu">
            <li></li>
        </ul>
    </div>

    <!-- security -->
    <div role="toolbar">
        <button mat-button class="dropdown-toggle" [matMenuTriggerFor]="menuUser" aria-haspopup="true"
            aria-expanded="false">
            <mat-icon aria-hidden="true" fontIcon="person_outline"></mat-icon>
            <span class="d-none d-md-inline-block"
                *ngIf="username && officeExternalId">{{username}} in {{officeExternalId}}</span>
        </button>
        <mat-menu #menuUser="matMenu">
            <ng-template matMenuContent>
                <a mat-menu-item [href]="'Common/ChangePassword'|link">{{'NAVIGATION.CHANGE_PASSWORD'|translate}}</a>
                <a mat-menu-item [href]="'Common/SecurityQuestion'|link">
                    {{'NAVIGATION.CHANGE_SECURITY_QUESTIONS'|translate}}</a>
                <ng-template [ngIf]="!isTrainingPendingLive && (isTrainingEnabled || trainingCompanyId !== '')">
                    <mat-divider class="mb-2 mt-2"></mat-divider>
                    <a id="goliveSwitch" href="#" mat-menu-item (click)="toggleTraining($event)">
                        {{(isTrainingEnabled?'TRAINING.LIVE_SWITCH':'TRAINING.TRAINING_SWITCH')|translate}}</a>
                </ng-template>
            </ng-template>
        </mat-menu>
    </div>

    <!-- logout -->
    <button id="btn_logout" mat-button (click)="logout();"
        [title]="'NAVIGATION.LOGOUT_TOOLTIP'|translate">
        <mat-icon aria-hidden="true" fontIcon="power_settings_new"></mat-icon>
        <span class="d-none d-md-inline-block">{{'NAVIGATION.LOGOUT'|translate}}</span>
    </button>
</div>