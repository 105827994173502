import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Security service.
 */
@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  /** Has dashboard role. */
  private _hasDashboardRole: BehaviorSubject<boolean>;

  /**
   * Initializes instance of the SecurityService class.
   * @param _http Htpp client.
   */
  constructor(private _http: HttpClient) {
    this._hasDashboardRole = new BehaviorSubject<boolean>(false);
    this._setDashboardRole();
  }

  /** Does the user have access to the Logi dashbhoard? */
  get hasDashboardRole(): Observable<boolean> {
    return this._hasDashboardRole.asObservable();
  }

  /**
   * Determine if the user has access to the Logi dashboard.
   */
  private _setDashboardRole(): void {
    const url = 'Home/GetUserRoles';
    this._http.get<boolean>(url).subscribe({
      next: value => this._hasDashboardRole.next(value),
      error: e => {
        console.error('SecurityService', '_setDashboardRole', url, e);
      }
    });
  }
}
