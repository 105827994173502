import { Component } from '@angular/core';

/**
 * Footer component.
 */
@Component({
  selector: 'ef-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {}
