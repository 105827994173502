import { IFailedFrameOrder } from '../interfaces/failed-frame-order';
import { EyeglassFrame } from '../../../classes/eyeglass-frame';
import { Lab } from './../../../classes/lab';
import { Patient } from './../../../classes/patient';

/**
 * Failed frame order class.
 */
export class FailedFrameOrder {
    /** Eyeglass frame. */
    public frame: EyeglassFrame;
    /** Lab. */
    public lab?: Lab;
    /** Order internal id. */
    public orderInternalId;
    /** Patient. */
    public patient: Patient;

    /** Frame order submitted date. (string) */
    private _submittedDate: string

    /**
     * Initializes instance of the FailedFrameOrder class.
     * @param order Failed frame order data.
     */
    constructor(order: IFailedFrameOrder) {
        this.frame = new EyeglassFrame(order.Frame);
        this.lab = order.Lab ? new Lab(order.Lab) : undefined;
        this.patient = new Patient(order.Patient);
        this.orderInternalId = order.OrderInternalId;
        this._submittedDate = order.SubmittedDate;
    }

    /** Link to order summary. */
    get orderSummaryLink(): string {
        return `Patient/EyeglassOrder?id=${this.patient.patientInternalId}&oid=${this.orderInternalId}`;
    }

    /** Frame order submitted date. */
    get submittedDate(): Date {
        return new Date(this._submittedDate);
    }
}