import { Component } from '@angular/core';

import { AppConfigurationService } from 'src/app/common/services/app-configuration/app-configuration.service';
import { AppFeatureService } from 'src/app/common/services/app-feature/app-feature.service';
import { IIdleTimeoutPlus } from 'src/app/common/interfaces/idle-timeout-plus';
import { IntegrationsService } from 'src/app/common/services/integrations/integrations.service';

/** TODO - ??? */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const IdleTimeoutPlus: IIdleTimeoutPlus;

/**
 * Component for the toolbar section of the header.
 */
@Component({
  selector: 'ef-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent {
  /** Has day close role? */
  public hasDayCloseRole = this._config.dailyRoles.hasDayCloseRole;
  /** Has day open role? */
  public hasDayOpenRole = this._config.dailyRoles.hasDayOpenRole;
  /** Has payment types role? */
  public hasPaymentTypesRole = this._config.dailyRoles.hasPaymentTypesRole;
  /** Is EHR enabled? */
  public isEhrEnabled = this._config.isEhrEnabled;
  /** Is payment authentication API enabled? */
  public isPaymentAuthApi = this._feature.isPaymentAuthApi;
  /** Is till enabled? */
  public isTillEnabled = this._feature.isTillEnabled;
  /** Is training enabled? */
  public isTrainingEnabled = this._config.isTrainingEnabled;
  /** Is go live pending? */
  public isTrainingPendingLive = this._config.isTrainingPendingLive;
  /** Number of daily roles. */
  public numberOfDailyRoles = this._config.dailyRoles.numberOfDailyRoles;
  /** Office external id. */
  public officeExternalId = this._config.officeExternalId;
  /** URL for support knowledge base. */
  public supportKnowledgeBaseUrl = this._config.supportKnowledgeBaseUrl;
  /** Training company id. */
  public trainingCompanyId = this._config.trainingCompanyId;
  /** Username. */
  public username = this._config.username;

  /**
   * Initializes instance of the HeaderToolbarComponent class.
   * @param _config Application configuration service.
   * @param _integrations Integrations service.
   * @param _feature Application feature service.
   * @param _training Training service.
   */
  constructor(
    private _config: AppConfigurationService,
    private _integrations: IntegrationsService,
    private _feature: AppFeatureService
  ) { }

  /** 
   * Open help link in new window.
   * @param event DOM event
   */
  help(event: Event): void {
    event.preventDefault();
    const a = event.target as HTMLElement;

    // TODO: This is not working.
    window.open(`${this._config.baseUrl}${a.getAttribute('data-url')}`, '_blank');
  }

  /**
   * Launch EHR.
   * @param event Dom event.
   */
  launchEhr(event: Event): void {
    event.preventDefault();
    this._integrations.launchEhr();

  }

  /**
   * Logout.
   */
  logout(): void {
    IdleTimeoutPlus.logout();
  }

  /**
   * Toggle training mode.
   * @param event DOM event.
   */
  toggleTraining(event: Event): void {
    event.preventDefault();
    this._config.toggleTraining();
  }

  /**
   * Walk Me Through launcher.
   * @param event DOM event
   */
  walkMeThroughLauncher(event: Event): void {
    event.preventDefault();
    this._config.walkMeThroughLauncher();
  }
}
