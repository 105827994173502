import { IEyeglassFrame } from '../interfaces/eyeglass-frame';

/**
 * Eyeglass frame class.
 */
export class EyeglassFrame {
    /** Item internal id. */
    public itemInternalId: number;
    /** Frame name. */
    public name: string;
    /** Item SKU. */
    public sku: string;
    /** UPC code. */
    public upc: string;

    /**
     * Initializes instance of the Frame class.
     * @param frame Frame data.
     */
    constructor(frame: IEyeglassFrame) {
        this.itemInternalId = frame.ItemInternalId;
        this.name = frame.Name;
        this.sku = frame.Sku;
        this.upc = frame.Upc;
    }
}
