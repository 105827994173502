import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { AlertType, IAlert } from '../../interfaces/alert';
import { AppConfigurationService } from '../app-configuration/app-configuration.service';
import { IPreviousUnclosedBusinessDaysInfo } from './interfaces/previous-unclosed-business-days-info';
import { environment } from 'src/environments/environment';

/**
 * Service for store operations.
 */
@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  
  /** Are patient nav order links disable? */
  private _isPatientNavOrderLinksDisabled = new BehaviorSubject<boolean>(false);
  /** Store operations alert.  */
  private _storeOperationsAlert = new BehaviorSubject<IAlert>({});

  /**
     * Initializes instance of the PatientsService class.
     * @param _config Application configuration service
     * @param _datePipe Date pipe
     * @param _http Http client.
     * @param _translate Translation service.
     * - Get previous unclosed business days info.
     */
  constructor(
    private _config: AppConfigurationService,
    private _datPipe: DatePipe,
    private _http: HttpClient,
    private _translate: TranslateService
  ) {
    this.loadPreviousUnclosedBusinessDaysInfo();
  }

  /**
   * Store operations alert. (observable for other components to subscribe)
   */
  get storeOperationsAlert(): Observable<IAlert> {
    return this._storeOperationsAlert.asObservable();
  }

  /**
   * Are patient nav order links disable? (observable for other components to subscribe)
   */
  get isPatientNavOrderLinksDisabled(): Observable<boolean> {
    return this._isPatientNavOrderLinksDisabled.asObservable();
  }

  /**
   * Load previous unclosed business days info.
   */
  loadPreviousUnclosedBusinessDaysInfo(): void {
    const officeExternalId = this._config.officeExternalId;
    if (officeExternalId) {
      const url = `DailyClosing/GetPreviousUnclosedBusinessDaysInfo?officeNumber=${encodeURIComponent(officeExternalId)}`;
      this._http.get<IPreviousUnclosedBusinessDaysInfo>(url).subscribe({
        next: data => this.setStoreOperationsAlert(data),
        error: () => this._storeOperationsAlert.next({})
      })
    }
  }

  /**
   * Set store operations alert.
   * @param data Previous unclosed business days info
   */
  setStoreOperationsAlert(data: IPreviousUnclosedBusinessDaysInfo): void {
    let postingDate = data.UnclosedDaysList?.length ? this._datPipe.transform(data.UnclosedDaysList[0], 'M/d/yyyy') : null;
    const unclosedDaysCount = data.UnclosedDaysCount;
    const tillEnabled = data.IsTillFeatureEnabled;
    const title = this._translate.instant('ALERTS.STORE_OPERATIONS.TITLE');
    let link = environment.links.dailyClosing;
    let message: string | undefined;
    let alertType: AlertType = 'Error';

    // set message   
    if (tillEnabled) {
      if (!data.IsDayOpen) {
        message = this._translate.instant('ALERTS.STORE_OPERATIONS.NOT_OPENED');
        link = environment.links.dailyOpening;
      }
      if (data.IsTodayClosed) {
        message = this._translate.instant('ALERTS.STORE_OPERATIONS.TODAY_CLOSED_TILL');
        link = environment.links.tillDailyClosing;
      }
      if (data.IsTodayClosingInProcess) {
        message = this._translate.instant('ALERTS.STORE_OPERATIONS.TODAY_CLOSING_IN_PROCESS');
        link = environment.links.tillDailyClosing;
      }
      if (unclosedDaysCount > 0) {
        message = this._translate.instant('ALERTS.STORE_OPERATIONS.UNCLOSED_DAYS', { postingDate });
        link = environment.links.tillDailyClosing;
      }
      if (data.UnopenedDaysCount > 0 && data.UnopenedDaysList?.length) {
        let dayStatus = 'previous';
        if (data.UnopenedDaysList?.length && (new Date(data.UnopenedDaysList[0])).getTime() === (new Date(data.ServerDateToday)).getTime()) {
          dayStatus = 'current';
        }
        postingDate = this._datPipe.transform(data.UnopenedDaysList[0], 'M/d/yyyy');
        message = this._translate.instant('ALERTS.STORE_OPERATIONS.UNOPENED_DAYS', { dayStatus, postingDate });
        link = environment.links.dailyOpening;
      }

      // update patient nav order links disabled flag
      this._isPatientNavOrderLinksDisabled.next(Boolean(message) || data.IsReopenEnabled);
    } else {
      let disableNavLinks = true;
      if (unclosedDaysCount === 0) {
        alertType = 'Info';
        if (data.IsTodayClosed) {
          message = this._translate.instant('ALERTS.STORE_OPERATIONS.TODAY_CLOSED');
        } else {
          disableNavLinks = false;
        }
      } else {
        if (!data.IsSmbDailyClosingEnabled) {
          message = this._translate.instant('ALERTS.STORE_OPERATIONS.SMB_DAILY_CLOSING_DISABLED');
          if (!this._config.dailyRoles.hasDayCloseRole) {
            message += `<br />${this._translate.instant('ALERTS.STORE_OPERATIONS.NO_ROLE')}`;
          }
        } else if (unclosedDaysCount === 1) {

          if (data.IsDailyClosingRequired) {
            message = this._translate.instant('ALERTS.STORE_OPERATIONS.DAILY_CLOSING_REQUIRED', { postingDate });
          } else {
            message = this._translate.instant('ALERTS.STORE_OPERATIONS.DAILY_CLOSING_NOT_REQUIRED', { postingDate });
            disableNavLinks = false;
          }
        } else {
          if (this._config.userId !== 0 && this._config.dailyRoles.hasDayCloseRole) {
            message = this._translate.instant('ALERTS.STORE_OPERATIONS.MULTIPLE_UNCLOSED_DAYS', { postingDate });
          } else {
            message = this._translate.instant('ALERTS.STORE_OPERATIONS.MULTIPLE_UNCLOSED_DAYS_NO_ROLE');
          }
        }
      }

      // update patient nav order links disabled flag
      this._isPatientNavOrderLinksDisabled.next((message?.length && disableNavLinks) || data.IsReopenEnabled);
    }

    // update store operations alert
    this._storeOperationsAlert.next({ link, message, title, type: alertType });
  }
}
