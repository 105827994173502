import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppFeatureService } from 'src/app/common/services/app-feature/app-feature.service';
import { SecurityService } from 'src/app/common/services/security/security.service';
import { environment } from 'src/environments/environment';

/**
 * Component for the navigation section of the header.
 */
@Component({
  selector: 'ef-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent implements OnDestroy, OnInit {
  /** Has dashboard role? */
  public hasDashboardRole = false;
  /** Is analytics and insights enabled? */
  public isAnalyticsAndInsightsEnabled = this._feature.isAnalyticsAndInsightsEnabled;
  /** Is production? */
  public isProduction = environment.production;

  /** Array of subscriptions to observables. */
  private _subscriptions: Subscription[] = [];

  /**
   * Initializes instance of the HeaderNavigationComponent class.
   * @param _feature Application feature service.
   * @param _security Security service.
   */
  constructor(
    private _feature: AppFeatureService,
    private _security: SecurityService
  ) {}

  /**
   * OnInit lifecycle hook.
   * -- Subscribe to has dashboard role flag.
   */
  ngOnInit(): void {
    this._subscriptions.push(this._security.hasDashboardRole.subscribe(value => this.hasDashboardRole = value));
  }

  /**
   * OnDestroy lifecycle hook.
   */
  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
