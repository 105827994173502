import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { AppFeatureService } from 'src/app/common/services/app-feature/app-feature.service';
import { PatientsService } from 'src/app/common/services/patients/patients.service';
import { ReviewPatientsDialog } from 'src/app/common/dialogs/review-patients/review-patients.dialog';
import { IAlert } from 'src/app/common/interfaces/alert';

/**
 * Component for review patients alert message.
 */
@Component({
  selector: 'ef-review-patients-alert',
  templateUrl: './review-patients-alert.component.html',
  styleUrls: ['./review-patients-alert.component.scss']
})
export class ReviewPatientsAlertComponent implements OnDestroy, OnInit {
  /** Review patients alert with default values. */
  public alert: IAlert = { type: 'Warning' };
  /** Review patients alert message. */
  public message = '';
  /** Number of patients added from the online scheduler that need review. */
  public patientCount = 0;

  /** Array of subscriptions to observables. */
  private _subscriptions: Subscription[] = [];

  /** Alert options. */
  @Input() set options(alert: IAlert) {
    Object.assign(this.alert, alert);
  }

  /** Template used to render content outside of selector. */
  @ViewChild('template') template!: TemplateRef<never>;

  /**
     * Initializes instance of the ReviewPatientsAlertComponent class.
     * @param _changeDetectorRef Change detector
     * @param _dialog Material dialog service.
     * @param _feature Application feature service
     * @param _patients Patients service
     */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _feature: AppFeatureService,
    private _patients: PatientsService
  ) { }

  /**
  * OnInit lifecycle hook.
  * - Subscribe to features.
  */
  ngOnInit(): void {
    this._feature.loadIsReviewPatientsCreatedOnlineEnabled();
    this._subscriptions.push(this._feature.isReviewPatientsCreatedOnlineEnabled.subscribe(enabled => {
      if (enabled) this.getPatientReviewCount(); else this.patientCount = 0;
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }));
  }

  /**
   * OnDestroy lifecycle hook.
   * - Unsubscribe from observables
   */
  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Show patient review dialog. TODO - replace with material dialog.
   * @param event DOM event
   */
  review(event: Event): void {
    event.preventDefault();

    this._dialog.open(ReviewPatientsDialog, {
      width: '95%',
      maxWidth: '950px',
    });
  }

  /**
   * Set the number of patients added from the online scheduler that need review.  Also determines
   * the message to display.
   */
  async getPatientReviewCount(): Promise<void> {
    this.patientCount = await this._patients.getPatientReviewCount();
    this.message = `ALERTS.PATIENTS_REVIEW.${this.patientCount === 1 ? 'SINGULAR' : 'PLURAL'}`
  }
}
