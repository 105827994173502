import { Component } from '@angular/core';

/**
 * Header component.
 */
@Component({
  selector: 'ef-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {}
