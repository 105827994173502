import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AppConfigurationService } from '../app-configuration/app-configuration.service';
import { IIntegrations } from './interfaces/integrations';

/**
 * Integrations service.
 */
@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  /** .NET Controller. */
  private _controller = 'AdditionalIntegrations';

  /**
   * Initializes instance of the IntegrationsService class.
   * @param _config Application configuraion service.
   * @param _http Http client. 
   * @param _santitizer DOM sanitizer.
   */
  constructor(
    private _config: AppConfigurationService,
    private _http: HttpClient,
    private _santitizer: DomSanitizer
  ) { }

  /**
   * Launch EHR.
   */
  launchEhr(): void {
    if (this._config.officeExternalId) {
      const officeExternalId = encodeURIComponent(this._config.officeExternalId);
      const url = `${this._controller}/GetModel?practiceLocationId=${officeExternalId}`;
      this._http.get<IIntegrations>(url).subscribe({
        next: data => {
          if (data.EhrDomain && data.EhrUrl) {
            const url = this._santitizer.sanitize(SecurityContext.URL, `https://${data.EhrDomain}.${data.EhrUrl}`);
            if (url) {
              window.open(url, '_blank');
            }
          }
        },
        error: e => {
          console.error('IntegrationsService', 'launchEhr', url, e);
          // TODO: Need to display error message.
          /*if (jqXhr.status === 404) {
            $(this).showSummaryMessage(msgType.SERVER_ERROR, "Could not find the exception.", true);
          } else {
            alert(jqXhr.status + " " + textStatus);
          }*/
        }
      });
    }
  }
}
