import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from './/footer.component';
import { FooterCopyrightComponent } from './footer-copyright/footer-copyright.component';
import { FooterQuicklinksComponent } from './footer-quicklinks/footer-quicklinks.component';

@NgModule({
  declarations: [
    FooterComponent,
    FooterCopyrightComponent,
    FooterQuicklinksComponent,
  ],
  exports: [
    FooterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class FooterModule { }
