import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

import { AppConfigurationService, FulfillmentService } from 'src/app/common';
import { IFindResults } from 'src/app/common/interfaces/find-results';
import { FailedFrameOrder } from 'src/app/common/services/fulfillment/classes/failed-frame-order';

/**
 * Component for reviewing failed frame orders.
 */
@Component({
  selector: 'ef-review-failed-orders',
  templateUrl: './review-failed-orders.component.html',
  styleUrls: ['./review-failed-orders.component.scss']
})
export class ReviewFailedOrdersComponent implements OnInit {
  /** Columns to display in table. */
  public displayedColumns: string[] = ['PatientName', 'OrderNum', 'UpdatedDate', 'UpcDescription', 'LabName', 'Action'];
  /** Are orders loaded from fulfilment service? */
  public isLoaded = false;
  /** Is routing available? */
  public isRoutingAvailable: boolean;
  /** Visible items to show in table. */
  public items = new BehaviorSubject<FailedFrameOrder[]>([]);
  /** Failed order results from fulfillment service. */
  public orders: IFindResults<FailedFrameOrder> = { Items: [], TotalAvailable: 0 };
  /** Active sort state. */
  public sort = this._fulfillment.defaultFailedFrameOrderSort;

  /** Page index. */
  private _pageIndex = 0;
  /** Page size. */
  private _pageSize = 10;

  /** Material paginator. */
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  /**
   * Initializes instance of the ReviewFailedOrdersComponent class.
   * @param _config Application configuration service
   * @param _fulfillment Fulfillment service
   */
  constructor(
    private _config: AppConfigurationService,
    private _fulfillment: FulfillmentService
  ) {
    this.isRoutingAvailable = this._config.isRoutingAvailable;
  }

  /**
   * OnInit lifecycle hook.
   * - Load failed orders.
   */
  ngOnInit(): void {
    this.loadFailedOrders();
  }

  /**
   * Acknowledge failed order.
   * @param event DOM event
   * @param orderInternalId Order internal id 
   */
  async acknowledge(event: Event, orderInternalId: number): Promise<void> {
    event.preventDefault();

    const success = await this._fulfillment.acknowledgeFailedFrameOrder(orderInternalId);
    if (success) {
      this.orders.Items = this.orders.Items.filter(x => x.orderInternalId != orderInternalId);
      this.orders.TotalAvailable--;
      this.setVisibleItems();
      this.loadFailedOrders();
    }
  }

  /**
   * Handle page events.
   * @param event Page event.
   */
  handlePageEvent(event: PageEvent): void {
    this._pageIndex = event.pageIndex;
    this._pageSize = event.pageSize;
    this.loadFailedOrders();
  }

  /**
   * Load failed orders from fulfilment service.
   * - Take extra items so the table can be updated quicker when an order is acknowledged.
   * - If the skip value exceeds the total available, go to the previous page. 
   */
  async loadFailedOrders(): Promise<void> {
    const skip = this._pageIndex * this._pageSize;
    const take = this._pageSize + 2;

    if (skip === 0 || skip < this.orders.TotalAvailable) {
      this.orders = await this._fulfillment.getFailedFrameOrders(skip, take, this.sort);
      this.setVisibleItems();
      this.isLoaded = true;
    } else {
      this.paginator.previousPage();
    }
  }

  /** 
   * Set visible items to show in table.
   */
  setVisibleItems(): void {
    this.items.next(this.orders.Items.slice(0, this._pageSize));
  }

  /**
   * Sort changed event.
   * @param sort Current sort state
   */
  sortChange(sort: Sort): void {
    this.sort = sort
    this.loadFailedOrders();
  }
}
