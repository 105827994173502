import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { IAlert } from 'src/app/common/interfaces/alert';
import { AppConfigurationService } from 'src/app/common/services/app-configuration/app-configuration.service';
import { OperationsService } from 'src/app/common/services/operations/operations.service';

/**
 * Component for store operations alert message.
 */
@Component({
  selector: 'ef-store-operations-alert',
  templateUrl: './store-operations-alert.component.html',
  styleUrls: ['./store-operations-alert.component.scss']
})
export class StoreOperationsAlertComponent implements OnDestroy, OnInit {
  /** Daily close alert with default values. */
  public alert: IAlert = { type: 'Error' };
  /** Has day close role? */
  public hasDayCloseRole = this._config.dailyRoles.hasDayCloseRole;

  /** Array of subscriptions to observables. */
  private _subscriptions: Subscription[] = [];

  /** Alert options. */
  @Input() set options(alert: IAlert) {
    Object.assign(this.alert, alert);
  }

  /** Template used to render content outside of selector. */
  @ViewChild('template') template!: TemplateRef<never>;

  /**
   * Initializes instance of the StoreOperationsAlertComponent class.
   * @param _changeDetectorRef Change detector
   * @param _config Application configuration service
   * @param _datePipe Date pipe
   * @param _operations Operaions service
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _config: AppConfigurationService,
    private _operations: OperationsService,
  ) { }

  /**
   * OnInit lifecycle hook.
   * -- Subscribe to daily close alert.
   */
  ngOnInit(): void {
    this._subscriptions.push(this._operations.storeOperationsAlert.subscribe(alert => {
      Object.assign(this.alert, alert);
      this.alert.style = this.hasDayCloseRole ? 'Clickable' : 'Basic';
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    }));
  }

  /**
   * OnDestroy lifecycle hook.
   */
  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Navigate to daily closing.
   */
  gotoDailyClosing(event: Event): void {
    event.preventDefault();
    if (this.alert.link) {
      window.location.href = this._config.getLinkPath(this.alert.link);
    }
  }
}
