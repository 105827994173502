import { Component, Inject } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';

import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

/**
 * System failure alert component.
 */
@Component({
  selector: 'ef-system-failure-alert',
  templateUrl: './system-failure-alert.component.html',
  styleUrls: ['./system-failure-alert.component.scss']
})
export class SystemFailureAlertComponent {
  /** HTTP error response. */
  error: HttpErrorResponse;

  /**
   * Initializes instance of SystemFailureAlertComponent.
   * @param _data HTTP error response
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) private _data: ISystemFailureAlertData) {
    this.error = this._data.error;
  }
}

/**
 * Interface for data injected into the system failure alert component.
 */
export interface ISystemFailureAlertData {
  /** HTTP error respone. */
  error: HttpErrorResponse;
  /** HTTP request. */
  request: HttpRequest<unknown>;
}
