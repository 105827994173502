import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { PageTitleComponent } from './page-title.component';
import { CustomPipesModule } from '../../pipes/custom-pipes.module';
import { PinnedNoteModule } from '../../dialogs/pinned-note/pinned-note.module';

@NgModule({
  declarations: [
    PageTitleComponent
  ],
  exports: [
    PageTitleComponent
  ],
  imports: [
    CommonModule,
    CustomPipesModule,
    MatDialogModule,
    PinnedNoteModule,
    TranslateModule
  ]
})
export class PageTitleModule { }
