import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TitleStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  ApiInterceptor,
  AppConfigurationService,
  CustomLoader,
  CustomTitleStrategy,
  ElementsModule,
  EyefinityModule,
  FooterComponent,
  HeaderComponent,
  NotificationsComponent
} from './common';
import { ReviewFailedOrdersComponent } from './frame-board-management/review-failed-orders/review-failed-orders.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EyefinityModule,
    HttpClientModule,
    ElementsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient, AppConfigurationService]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: TitleStrategy, useClass: CustomTitleStrategy }
  ]
})
export class AppModule implements DoBootstrap {
  constructor(
    private _injector: Injector,
    private _translateService: TranslateService) { }

  ngDoBootstrap(appRef: ApplicationRef): void {
    // wait for translations to load (better UI experience)
    lastValueFrom(this._translateService.use('en')).then(() => {

      // if root element exists, bootstrap the AppComponent, otherwise create custom elements
      const efRoot = document.querySelector('ef-root');
      if (efRoot) {
        // Do not load SPA in staging and production.  TODO - remove if block when SPA is ready to deploy
        if(environment.development) {
          appRef.bootstrap(AppComponent);
        }
      } else {
        const footer = createCustomElement(FooterComponent, { injector: this._injector });
        customElements.define('ef-footer', footer);

        const header = createCustomElement(HeaderComponent, { injector: this._injector });
        customElements.define('ef-header', header);

        const notifications = createCustomElement(NotificationsComponent, { injector: this._injector });
        customElements.define('ef-notifications', notifications);

        const reviewFailedOrders = createCustomElement(ReviewFailedOrdersComponent, { injector: this._injector });
        customElements.define('ef-review-failed-orders', reviewFailedOrders);
      }
    });
  }
}
