import { IPatient } from '../interfaces/patient';

/**
 * Patient class.
 */
export class Patient {
    /** Patient first name. */
    public firstName: string;
    /** Patient last name. */
    public lastName: string;
    /** Middle initial. */
    public middleInitial?: string;
    /** Nick name. */
    public nickName?: string;
    /** Patient internal id. */
    public patientInternalId: number;

    /**
     * Initializes instance of the Patient class.
     * @param patient Patient data.
     */
    constructor(patient: IPatient) {
        this.firstName = patient.FirstName;
        this.lastName = patient.LastName;
        this.middleInitial = patient.MiddleInitial;
        this.nickName = patient.NickName;
        this.patientInternalId = patient.PatientInternalId;
    }

    /** Link to patient demographics. */
    get demographicsLink(): string {
        return `Patient/Demographics?id=${this.patientInternalId}`;
    }

    /** Full name. */
    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    /** Link to patient overview. */
    get overviewLink(): string {
        return `Patient/Overview?id=${this.patientInternalId}`;
    }
}
