<div class="align-items-center d-flex mb-3">
    <h2>
        <ng-template [ngIf]="patient || area">
            <span *ngIf="!patient && area">{{area | translate}}</span>
            <a [href]="patient.demographicsLink | link" class="link-simple" *ngIf="patient">
                {{patient.firstName}} {{patient.lastName}}</a>&nbsp;:&nbsp;
        </ng-template>
        <span *ngIf="pageTitle">{{ pageTitle | translate }}</span>
    </h2>
    <i id="patientPushPin" class="btn icon-pushpin" tabindex="0" (click)="openPinnedNote()" *ngIf="patient"></i>
</div>