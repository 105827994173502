import { Component } from '@angular/core';

import { AppConfigurationService } from 'src/app/common/services/app-configuration/app-configuration.service';

/**
 * Component for the account switch, copyright and version sections of the footer.
 */
@Component({
  selector: 'ef-footer-copyright',
  templateUrl: './footer-copyright.component.html',
  styleUrls: ['./footer-copyright.component.scss']
})
export class FooterCopyrightComponent {
  /** IS training enabled? */
  public isTrainingEnabled = this._config.isTrainingEnabled;
  /** Application version. */
  public version = this._config.appVersion;
  /** Current year to display with the copyright. */
  public year = new Date().getFullYear();

  /**
   * Initializes instance of the FooterCopyrightComponent class.
   * @param _config Application configuration service.
   * @param _training Training service.
   */
  constructor(
    private _config: AppConfigurationService
  ) { }

  /**
   * Toogle training mode.
   * @param event DOM event.
   */
  toggleTraining(event: Event): void {
    event.preventDefault();
    this._config.toggleTraining();
  }
}
