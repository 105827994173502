import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { LogoComponent } from './logo.component';
import { CustomPipesModule } from '../../pipes/custom-pipes.module';



@NgModule({
  declarations: [
    LogoComponent
  ],
  exports: [
    LogoComponent
  ],
  imports: [
    CommonModule,
    CustomPipesModule,
    TranslateModule,
  ]
})
export class LogoModule { }
