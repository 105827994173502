import { Pipe, PipeTransform } from '@angular/core';

import { AppConfigurationService } from '../services/app-configuration/app-configuration.service';

/**
 * Pipe used for generating the full path to a link.
 */
@Pipe({
  name: 'link'
})
export class LinkPipe implements PipeTransform {

  /**
   * Initializes an instance of the LinkPipe class.
   * @param _config Application configuration service.
   */
  constructor(private _config: AppConfigurationService) { }

  /** 
   * Generate the full link path. 
   * @param relativePath Relative path for the link.
   */
  transform(relativePath: string): string {
    return this._config.getLinkPath(relativePath);
  }
}
