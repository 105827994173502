import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationsComponent } from '../../home/components/notifications/notifications.component';
import { EyefinityModule } from '../eyefinity.module';
import { ReviewFailedOrdersModule } from 'src/app/frame-board-management/review-failed-orders/review-failed-orders.module';

@NgModule({
    declarations: [
        NotificationsComponent
    ],
    exports: [
        NotificationsComponent,
        ReviewFailedOrdersModule
    ],
    imports: [
        CommonModule,
        EyefinityModule,
        MatCardModule,
        ReviewFailedOrdersModule,
        TranslateModule
    ]
})
export class ElementsModule { }
