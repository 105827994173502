import { IPatient } from '../common/interfaces/patient';

export const PATIENTS_DATA: IPatient[] = [{
    PatientInternalId: 12345,
    FirstName: 'Johnny',
    LastName: 'Smith',
    MiddleInitial: 'M',
    NickName: 'John'
},
{
    PatientInternalId: 23456,
    FirstName: 'Bette',
    LastName: 'Davis'
}];