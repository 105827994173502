import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AssetPipe } from './asset.pipe';
import { LinkPipe } from './link.pipe';

@NgModule({
  declarations: [
    AssetPipe,
    LinkPipe
  ],
  exports: [
    AssetPipe,
    LinkPipe
  ],
  imports: [
    CommonModule
  ]
})
export class CustomPipesModule { }
