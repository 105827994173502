import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { AppConfigurationService } from '../services/app-configuration/app-configuration.service';
import { SystemFailureAlertComponent } from '../components/alerts/system-failure-alert/system-failure-alert.component';
import { IAppWindow } from '../services/app-configuration/interfaces/app-window';

/** Extened window DOM document. */
declare const window: IAppWindow;

/**
 * HTTP interceptor for EPM API calls.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  /**
   * Initializes instance of the ApiInterceptor class.
   * @param _config App configuraion service.
   * @param _snackBar Material snackbar.
   * @param _translate Translation service.
   */
  constructor(
    private _config: AppConfigurationService,
    private _snackBar: MatSnackBar,
    private _translate: TranslateService
  ) { }

  /**
   * Itercept HTTP request.
   * @param request HTTP request
   * @param next HTTP handler
   * @returns {Observable}
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.handle(request, next);
  }

  /**
   * Add common headers to request.
   * @param request HTTP request
   * @param next HTTP handler
   * @returns Observable
   */
  handle(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url.indexOf('assets') < 0) {
      // get anti forgery token
      const antiForgeryToken = this._config.antiForgeryToken;

      // if token exists, update request, otherwise throw error
      if (antiForgeryToken) {
        const useOffice = (request.url.match(/Login|AdditionalIntegrations|Home|CompanyInformation/gi)?.length || 0) === 0;
        const url = `${this._getApiBaseUrl(useOffice)}/${request.url}`;

        Object.assign(
          request, {
          url,
          headers: new HttpHeaders({
            'RequestVerificationToken': antiForgeryToken,
            'content-type': 'application/json; charset=utf-8'
          }),
          responseType: 'json'
        });
      } else {
        throw new Error(this._translate.instant('TOKEN_NOT_FOUND') as string);
      }

      // return updated request
      request = request.clone();
    }

    this._updateLoaderCount(1);

    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this._updateLoaderCount(-1);

        let isTimeout = false;

        if (error.status === 419) {
          isTimeout = true;
          const url = window.parent.location.href;
          if (url.indexOf('Login') < 0) {
            window.location.href = `${this._config.getLinkPath('Login')}?ReturnUrl=${encodeURIComponent(url)}`;
          } else {
            location.reload();
          }
        }

        this._snackBar.openFromComponent(SystemFailureAlertComponent, {
          panelClass: isTimeout ? 'timeout' : 'system-failure',
          data: { error, request }
        });
        return throwError(() => new Error(error.message));
      }))
      .pipe(map(event => {
        if (event instanceof HttpResponse) {
          this._updateLoaderCount(-1);
        }
        return event;
      }));
  }

  /**
   * Get API base url. 
   * @param useOffice Use office? 
   * @returns {string}
   */
  private _getApiBaseUrl(useOffice: boolean): string {
    const url = this._config.baseUrl;
    const officeExternalId = this._config.officeExternalId;

    if (url) {
      if (useOffice && officeExternalId) {
        return `${url.endsWith('/') ? url.substring(0, url.length - 1) : url}/api/office/${officeExternalId}`;
      } else {
        return `${url.endsWith('/') ? url.substring(0, url.length - 1) : url}/api`;
      }
    } else {
      throw new Error(this._translate.instant('CONFIG_MISSING') as string);
    }
  }

  /**
   * Update loader count used by the jQuery spinner. TODO: will need to be updated when SPA enabled.
   * @param n Value used to increment/decrement loader count.
   */
  private _updateLoaderCount(n: number): void {
    const updateLoaderCount = window.updateLoaderCount;
    if (updateLoaderCount) {
      updateLoaderCount(n);
    }
  }
}
