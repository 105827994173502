import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

/**
 * Class for custom title strategy.
 */
@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
    /**
     * Initializes instance of the CustomTitleStrategy class.
     * @param _translate Translation service
     */
    constructor(private _translate: TranslateService) {
        super();
    }

    /**
     * Override for the default updateTitle method which translates the title and appends the application name.
     * @param routerState Router state
     */
    override updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.buildTitle(routerState);
        if (title) {
            document.title = `${this._translate.instant('APP_NAME_SHORT')} - ${this._translate.instant(title)}`;
        } else {
            document.title = `${this._translate.instant('APP_NAME')}`;
        }
    }
}