<mat-toolbar data-ef="pizza" class="header-navbar">
    <div class="d-flex d-md-none flex-fill">
        <button mat-button class="mobile-menu" [matMenuTriggerFor]="menuMobile" role="button">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menuMobile="matMenu">
            <ng-template matMenuContent>
                <!-- TODO: Mobile menu goes here -->
            </ng-template>
        </mat-menu>
    </div>
    <div class="d-none d-md-flex flex-fill">
        <div class="d-flex gap-1">

            <!-- patients menu -->
            <button data-ef="nav-patients" mat-button [matMenuTriggerFor]="menuPatients" role="button">
                {{'NAVIGATION.PATIENTS'|translate}}</button>

            <mat-menu #menuPatients="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-patient-search" mat-menu-item [href]="'Patient/Search'|link">
                        {{'NAVIGATION.SEARCH_ADD'|translate}}</a>
                    <a data-ef="nav-patient-merge" mat-menu-item [href]="'Patient/Merge'|link">
                        {{'NAVIGATION.MERGE_PATIENTS'|translate}}</a>

                    <!-- TODO: Is this link always visible? -->
                    <a data-ef="nav-patient-vsp-authorizations" id="VSPAuthorization" mat-menu-item [href]="'Patient/VSPAuthorizations'|link">
                        {{'NAVIGATION.VSP_AUTHORIZATIONS'|translate}}</a>

                    <!-- TODO: Is this used? -->
                    <a data-ef="nav-patient-vsp-member-search" mat-menu-item [href]="'Patient/VspMemberSearch'|link" *ngIf="false">
                        {{'NAVIGATION.VSP_MEMBER_SEARCH'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- appointments menu -->
            <button data-ef="nav-appointments" mat-button [matMenuTriggerFor]="menuAppointments" role="button">
                {{'NAVIGATION.APPOINTMENTS'|translate}}</button>

            <mat-menu #menuAppointments="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-appointments-calendar" mat-menu-item [href]="'Appointments/Calendar'|link">
                        {{'NAVIGATION.APPOINTMENT_CALENDAR'|translate}}</a>
                    <a data-ef="nav-appointments-confirmations" mat-menu-item [href]="'Appointments/AppointmentConfirmations'|link">
                        {{'NAVIGATION.APPOINTMENT_CONFIRMATION'|translate}}</a>
                    <a data-ef="nav-appointments-resource-schedule" mat-menu-item [href]="'Appointments/ResourceSchedule'|link">
                        {{'NAVIGATION.RESOURCE_SCHEDULE'|translate}}</a>
                    <a data-ef="nav-appointments-holidays" mat-menu-item [href]="'Appointments/Holidays'|link">
                        {{'NAVIGATION.HOLIDAYS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- orders menu -->
            <button data-ef="nav-orders" mat-button [matMenuTriggerFor]="menuOrders" role="button">
                {{'NAVIGATION.ORDERS'|translate}}</button>

            <mat-menu #menuOrders="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-orders-management" mat-menu-item [href]="'Management/OrderManagement'|link">
                        {{'NAVIGATION.ORDER_MANAGEMENT'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- catalog menu -->
            <button data-ef="nav-catalog" mat-button [matMenuTriggerFor]="menuCatalog" role="button">
                {{'NAVIGATION.CATALOG'|translate}}</button>

            <mat-menu #menuCatalog="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-catalog-frame" mat-menu-item [href]="'ProductCatalog/FrameLookup'|link">
                        {{'NAVIGATION.FRAMES'|translate}}</a>
                    <a data-ef="nav-catalog-eyeglass-lens" mat-menu-item [href]="'ProductCatalog/EyeglassLensLookup'|link">
                        {{'NAVIGATION.EYEGLASS_LENSES'|translate}}</a>
                    <a data-ef="nav-catalog-contact-lens" mat-menu-item [href]="'ProductCatalog/ContactLensLookup'|link">
                        {{'NAVIGATION.CONTACT_LENSES'|translate}}</a>
                    <a data-ef="nav-catalog-accessory" mat-menu-item [href]="'ProductCatalog/AccessoryLookup'|link">
                        {{'NAVIGATION.ACCESSORIES'|translate}}</a>
                    <a data-ef="nav-catalog-insurance-plan" mat-menu-item [href]="'ProductCatalog/InsurancePlanLookup'|link">
                        {{'NAVIGATION.INSURANCE_PLANS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- claim management menu (gt-md) -->
            <button data-ef="nav-claim-management" mat-button class="d-none d-lg-block" [matMenuTriggerFor]="menuClaimManagement"
                role="button">{{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</button>

            <mat-menu #menuClaimManagement="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-claim-management-billing" mat-menu-item [href]="'Billing'|link">
                        {{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- onventory menu (gt-md) -->
            <button data-ef="nav-inventory" mat-button class="d-none d-lg-block" [matMenuTriggerFor]="menuInventory"
                role="button">{{'NAVIGATION.INVENTORY'|translate}}</button>

            <mat-menu #menuInventory="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-inventory-product" mat-menu-item [href]="'Product/Inventory'|link">
                        {{'NAVIGATION.PRODUCT_INVENTORY'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- reporting menu (gt-md) -->
            <button data-ef="nav-reporting" mat-button class="d-none d-lg-block" [matMenuTriggerFor]="menuReporting"
                role="button">{{'NAVIGATION.REPORTING'|translate}}</button>

            <mat-menu #menuReporting="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-reporting-my-dashboard" mat-menu-item [href]="'Reporting/MyDashboardReports'|link" *ngIf="hasDashboardRole">
                        {{'NAVIGATION.DASHBOARD'|translate}}</a>
                    <a data-ef="nav-reporting-my-reports" mat-menu-item [href]="'Reporting/MyReports'|link">
                        {{'NAVIGATION.STANDARD_REPORTS'|translate}}</a>
                    <a data-ef="nav-reporting-analytics-insights" mat-menu-item [href]="'Reporting/AnalyticsAndInsights'|link"
                        *ngIf="isAnalyticsAndInsightsEnabled">{{'NAVIGATION.ANALYTICS'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- more menu (md) -->
            <button data-ef="nav-more" mat-button class="d-block d-lg-none" [matMenuTriggerFor]="menuMore"
                role="button">{{'NAVIGATION.MORE'|translate}}</button>

            <mat-menu #menuMore="matMenu">
                <ng-template matMenuContent>
                    <a data-ef="nav-more-billing" mat-menu-item [href]="'Billing'|link">
                        {{'NAVIGATION.CLAIM_MANAGEMENT'|translate}}</a>
                    <a data-ef="nav-more-inventory" mat-menu-item [href]="'Product/Inventory'|link">
                        {{'NAVIGATION.PRODUCT_INVENTORY'|translate}}</a>
                    <a data-ef="nav-more-reporting" mat-menu-item [matMenuTriggerFor]="menuReporting">
                        {{'NAVIGATION.REPORTING'|translate}}</a>
                </ng-template>
            </mat-menu>

            <!-- demo menu (xl) TODO: remove when SPA enabled -->
            <button mat-button class="d-none d-xl-block" [matMenuTriggerFor]="menuDemo"
                role="button" *ngIf="!isProduction">Demo</button>

            <mat-menu #menuDemo="matMenu">
                <ng-template matMenuContent>
                    <a mat-menu-item efRoute="/Patient/12345/MaterialOrders/12345">Pricing</a>
                    <a mat-menu-item efRoute="/FrameBoardManagement/ReviewFailedOrders">Review Failed Orders</a>
                </ng-template>
            </mat-menu>

        </div>
        <div class=" d-none d-md-flex flex-fill justify-content-end">

            <!-- Quick List -->
            <button data-ef="nav-quick-list" mat-button [matMenuTriggerFor]="menuQuickList" role="button">
                {{'NAVIGATION.QUICK_LIST'|translate}}</button>
            <mat-menu #menuQuickList="matMenu">
                <ng-template matMenuContent>
                    <!-- TODO: Quick list goes here -->
                </ng-template>
            </mat-menu>

        </div>
    </div>
</mat-toolbar>