import { Directive, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfigurationService } from '../services/app-configuration/app-configuration.service';

/**
 * Directive that automatically switchings between angular routes and normal hyperlinks. 
 */
@Directive({
  selector: '[efRoute]'
})
export class RouteDirective {
  /** Router link. */
  @Input() efRoute = '';

  /**
   * Initializes instance of the RouteDirective class.
   * @param _config Application configuration service.
   * @param _element Reference to to the element.
   * @param _router Router service.
   */
  constructor(
    private _config: AppConfigurationService,
    private _element: ElementRef<HTMLAnchorElement>,
    private _router: Router
  ) {
    this._element?.nativeElement?.addEventListener('click', (event: Event) => {
      event.preventDefault();
      if (_config.isRoutingAvailable) {
        this._router.navigate([this.efRoute]);
      } else {
        window.location.href = `${this._config.clientBasePath}${this.efRoute}`;
      }
    });
  }
}