<ef-page-title *ngIf="isRoutingAvailable; else angularElementTitle"></ef-page-title>

<!-- TODO: remove when SPA is enabled -->
<ng-template #angularElementTitle>
    <h2 class="h2-override">{{ 'FRAME_BOARD_MANAGEMENT.REVIEW_FAILED_ORDERS.PAGE_TITLE' | translate }}</h2>
</ng-template>

<ng-template [ngIf]="orders.TotalAvailable>0" [ngIfElse]="noData">
    <!-- Instructions -->
    <p [innerHTML]="'FRAME_BOARD_MANAGEMENT.REVIEW_FAILED_ORDERS.INSTRUCTIONS' | translate"></p>

    <!-- Failed orders -->
    <table mat-table [dataSource]="items" matSort [matSortActive]="sort.active" [matSortDirection]="sort.direction"
        (matSortChange)="sortChange($event)">

        <!-- Patient Name -->
        <ng-container matColumnDef="PatientName">
            <th mat-header-cell *matHeaderCellDef>{{ 'TABLES.COLUMN_PATIENT_NAME' | translate }}</th>
            <td mat-cell *matCellDef="let item">
                <a [href]="item.patient.overviewLink|link">{{ item.patient.fullName }}</a>
            </td>
        </ng-container>

        <!-- Order Number -->
        <ng-container matColumnDef="OrderNum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLES.COLUMN_ORDER_NUMBER' | translate }}</th>
            <td mat-cell *matCellDef="let item">
                <a [href]="item.orderSummaryLink|link">{{ item.orderInternalId }}</a>
            </td>
        </ng-container>

        <!-- Date of Order -->
        <ng-container matColumnDef="UpdatedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TABLES.COLUMN_ORDER_DATE' | translate }}</th>
            <td mat-cell *matCellDef="let item">{{ item.submittedDate | date : 'MM/dd/yyyy' }}</td>
        </ng-container>

        <!-- Frame SKU / Description -->
        <ng-container matColumnDef="UpcDescription">
            <th mat-header-cell *matHeaderCellDef>{{ 'TABLES.COLUMN_FRAME_UPC_DESCRIPTION' | translate }}</th>
            <td mat-cell *matCellDef="let item">{{ item.frame.upc }} / {{ item.frame.name }}</td>
        </ng-container>

        <!-- Lab Name -->
        <ng-container matColumnDef="LabName">
            <th mat-header-cell *matHeaderCellDef>{{ 'TABLES.COLUMN_LAB_NAME' | translate }}</th>
            <td mat-cell *matCellDef="let item">{{ item.lab?.name }}</td>
        </ng-container>

        <!-- Action -->
        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>{{ 'TABLES.COLUMN_ACTION' | translate }}</th>
            <td mat-cell *matCellDef="let item">
                <button mat-button class="color-link" (click)="acknowledge($event, item.orderInternalId)">
                    {{ 'FRAME_BOARD_MANAGEMENT.REVIEW_FAILED_ORDERS.BUTTON_ACKNOWLEDGE' | translate }}</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Paginator -->
    <mat-paginator #paginator [hidePageSize]="true" [pageSize]="10" [showFirstLastButtons]="true"
        [length]="orders.TotalAvailable" [attr.aria-label]="'TABLES.PAGINATOR_LABEL'|translate"
        (page)="handlePageEvent($event)">
    </mat-paginator>

</ng-template>

<ng-template #noData>
    <p *ngIf="isLoaded">{{ 'FRAME_BOARD_MANAGEMENT.REVIEW_FAILED_ORDERS.NO_DATA' | translate }}</p>
</ng-template>