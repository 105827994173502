import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { MatDialog } from '@angular/material/dialog';

import { PinnedNoteDialog } from '../../dialogs/pinned-note/pinned-note.dialog';
import { Patient } from '../../classes/patient';

/**
 * Component for the page title with patient name and pinned note.
 */
@Component({
  selector: 'ef-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  /** Page title. */
  public pageTitle = '';

  /** Area ??? */
  @Input() area!: string;
  /** Patient. */
  @Input() patient!: Patient;

  /**
   * Initializes instance of the PageTitleComponent class.
   * @param _dialog Material dialog service.
   * @param _titleService Title service used to set the browser page/tab title.
   */
  constructor(
    private _dialog: MatDialog,
    private _titleService: Title,
  ) { }

  /**
   * OnInit lifecycle hook.
   * - Get page title from title service.
   */
  ngOnInit(): void {
    const title = this._titleService.getTitle();
    const separator = title.indexOf(' - ');
    this.pageTitle = title.substring(separator + 3);
  }

  /**
   * Open pinned note dialog.
   */
  openPinnedNote(): void {
    this._dialog.open(PinnedNoteDialog, {
      width: '95%',
      maxWidth: '600px',
      data: { patient: this.patient }
    });
  }
}
