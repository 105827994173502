import { Component, Input, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AppConfigurationService } from '../../services/app-configuration/app-configuration.service';

/**
 * Component for the Eyefinity/Encompass logo.
 */
@Component({
  selector: 'ef-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  /** Relative path to the logo asset (default set to 1-pixel transparent image). */
  public imageSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  /** Is the logo on a dark background? */
  @Input() isOnDarkBackground = false;
  /** Is the premier logo enabled? */
  @Input() isPremierLogoEnabled = false;

  /**
   * Initializes an instance of the LogoComponent class.
   * @param _config Application configuration service.
   */
  constructor(
    private _config: AppConfigurationService,
    private _translate: TranslateService
  ) { }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit(): void {
    // TODO - ??? if (this.isPremierLogoEnabled && this._config.isPremier) {
    let imageSrc = this._translate.instant(this.isOnDarkBackground ? 'LOGO.FOR_DARK_BG' : 'LOGO.FOR_LIGHT_BG');
    
    if (typeof imageSrc === 'string' && imageSrc.indexOf('https://') !== 0) {
      imageSrc = this._config.getAssetPath(imageSrc);
    }
    this.imageSrc = imageSrc;
  }
}
