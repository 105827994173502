import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReviewFailedOrdersComponent } from './review-failed-orders.component';

const routes: Routes = [
  { path: '', component: ReviewFailedOrdersComponent, title: 'FRAME_BOARD_MANAGEMENT.REVIEW_FAILED_ORDERS.PAGE_TITLE' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReviewFailedOrdersRoutingModule { }