import { Component } from '@angular/core';

import { AppConfigurationService } from 'src/app/common/services/app-configuration/app-configuration.service';

/**
 * Component for the quicklinks section of the footer.
 */
@Component({
  selector: 'ef-footer-quicklinks',
  templateUrl: './footer-quicklinks.component.html',
  styleUrls: ['./footer-quicklinks.component.scss']
})
export class FooterQuicklinksComponent {
  /**
   * Initializes instance of the FooterQuicklinksComponent class.
   * @param _config Application configuration service.
   */
  constructor(private _config: AppConfigurationService) { }

  /**
   * Navigate to admin dashboard.
   * @param event DOM event.
   */
  goToAdmin(event: Event): void {
    event.preventDefault();

    // TODO - Show daily closing warning dialog.
    // if (unclosedDaysCount !== undefined && unclosedDaysCount > 0) {
    // showDailClosingWarningDialog("Admin");
    // } else {
      if (window.sessionStorage.getItem('adminOfficeContext')) {
        window.sessionStorage.removeItem('adminOfficeContext');
      }
      window.location.href = this._config.getLinkPath('Admin');
    // }
  }
}
