import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './header.component';
import { HeaderNavigationComponent } from './header-navigation/header-navigation.component';
import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';
import { LogoModule } from '../logo/logo.module';
import { CustomPipesModule } from '../../pipes/custom-pipes.module';
import { RouteDirective } from '../../directives/route.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderNavigationComponent,
    HeaderToolbarComponent,
    RouteDirective
  ],
  exports: [
    HeaderComponent,
    RouteDirective
  ],
  imports: [
    CommonModule,
    CustomPipesModule,
    LogoModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    RouterModule,
    TranslateModule
  ]
})
export class HeaderModule { }
