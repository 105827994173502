import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Patient } from '../../classes/patient';
import { PATIENTS_DATA } from 'src/app/mock-data';

/**
 * Service used for working with patient data.
 */
@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  /** API controller. */
  private _controller = 'Patient';

  /**
   * Initializes instance of the PatientsService class.
   * @param _http Http client.
   */
  constructor(private _http: HttpClient) { }

  /**
   * Get patient details from API controller. (TODO)
   * @param patientInternalId Patient internal id.
   * @returns Patient object.
   */
  async getPatient(patientInternalId: number): Promise<Patient | undefined> {
    const patient = PATIENTS_DATA.find(x => x.PatientInternalId === patientInternalId);
    return patient ? new Patient(patient) : undefined;
  }

  /**
   * Get the number of patients added from the online scheduler that need review.
   * @returns Patient count.
   */
  async getPatientReviewCount(): Promise<number> {
    const url = `${this._controller}/GetReviewNeededPatientCount`;
    return new Promise<number>(resolve => this._http.get<number>(url).subscribe({
      next: count => resolve(count),
      error: () => resolve(0)
    }));
  }
}
