import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

/**
 * Dialog component for the patient pinned note.
 */
@Component({
  selector: 'ef-pinned-note',
  templateUrl: './pinned-note.dialog.html',
  styleUrls: ['./pinned-note.dialog.scss']
})
export class PinnedNoteDialog {
  /**
   * Initializes instance of the PinnedNoteDialog class.
   * @param dialogRef Reference to this dialog.
   */
  constructor(public dialogRef: MatDialogRef<PinnedNoteDialog>) { }

  /**
   * Save note.
   */
  save(): void {
    this.dialogRef.close('save');
  }
}
