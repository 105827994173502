import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';

/**
 * Notifications card.
 */
@Component({
  selector: 'ef-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements AfterContentChecked {
  /** Is the component hidden? */
  @HostBinding('hidden') isHidden = true;

  /** Reference to material card content element. */
  @ViewChild('content') content!: ElementRef;

  /**
   * Initializes instance of NotificationsComponent class.
   * @param _changeDetectorRef Change detector
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  /**
   * AfterContentChecked lifecycle hook.
   * - Unhide the component if there are alerts.
   */
  ngAfterContentChecked(): void {
    const alertCount = this.content?.nativeElement.querySelectorAll('ef-alert').length || 0;
    this.isHidden = alertCount === 0;
    this._changeDetectorRef.detectChanges();
  }
}
